@font-face {
  font-family: "regular";
  src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "bold";
  src: url("./assets/fonts/Nunito-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
body{
 background: url(./assets/images/bodybackground.jpg) no-repeat fixed top center;
background-size: cover;
}
.lg-font{font-size: 50px !important;}
.chutti{mix-blend-mode: darken;}
.over{overflow: hidden;}
.regular{font-family: "regular";}
.bold{font-family: "bold";}
/* default start */
li{list-style: none;}
a{text-decoration: none;}
.padding{padding: 90px 0;}
.foot-pad{padding:30px 0  ;}
.logo{width:120px;}
.navbar-expand-lg .navbar-nav .nav-link { 
    padding: 10px 40px !important;
font-family: "bold";
    font-size: 17px;
}
.mob-icon{width: 50px;}
.navfont .nav-link.active {
    color: #ffc105 !important;
    border-bottom: 2px solid #ffc105;
  }
  .navbar-nav .nav-link.active {
    color: #070707 !important;
    border-radius: 50px;
    background-color:#ffc105 ;
  }
  .fullpad{padding: 0 !important;
    margin: 0 !important;}
    .bg-overlay{position: relative;}
    .bg-overlay::before{
      background: #000000ab none repeat scroll 0 0;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .z{position: relative; z-index: 2;}
/* default end */
/* app bar start */
.top-bg{
  background-image: url("./assets/images/topbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}
.top-bg::before{
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.top-info li p {
  display: inline-block;
  margin-bottom: 8px;
}
.top-info li svg {
  font-size: 20px;
  margin-right: 16px;
}
#home {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: 50% 0px;
}
.home-center {
  height: 100vh;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}
  .home-center img{
    width: 200px;
    margin: 0 auto;
  }
  .down svg{
    color: #ffffff;
    font-size: 55px;
  
  }
.quote{font-size: 55px; 
    color: #fff;
    font-family: 'regular';}
.mark-bg{ background-color: #ffc105;
  color: #1f1d1d;
  font-family: "regular";}
  .estimate{
    background: #6482AD !important;
    padding: 10px !important;
    font-family: "bold" !important;
    color: #fff !important;
  }
/* app bar end */
/* footer start */
.list-unstyled li{padding: 12px 0;}
.list-unstyled li a {
  text-decoration: none !important;
  color: #ffffff !important;
  font-family: 'regular';
}
.rules{line-height: 35px;
font-family: 'regular';}
.footer-bg{ background:url(./assets/images/foot-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
font-family: "regular";}
.ft-logo{
  width:150px;
}
.ft-icon{
  background: #ffc105;
  padding: 12px;
  border-radius: 50%;
  color: #141313;
  font-size: 32px;
  line-height: 10px;
   }
.ft-details{
  margin: 0 20px;
   
}
/* footer end */
/* site off start */
.site-off{
 background-image: url(./assets/images/siteoffbanner.jpg);
 background-size: cover;
 height: 100vh;
 background-repeat: no-repeat;
 background-position:center;
 position: relative;
}
.site-off::before{
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.site-of-text h2{
  position: relative;
  z-index: 2;
 
}
.site-of-text{
  position: relative;
  z-index: 2;
 
}
.site-of {
  width: 25%;
}