.title{padding: 12px 30px; 
    display: inline-block; 
    color: #fff; 
    border-radius: 10px; 
    font-size: 20px; 
    font-family: "bold";}
.bg-green{background-color: #038403;}
.bg-red{background-color: red;}
.safe-list{display: flex; align-items: center;}
.safe-icon svg{font-size: 37px;}
.box-green{background-color:  green;
    border-radius: 8px;
    padding: 12px;
    color: #ffffff;
    margin: 10px 0;font-family: "regular";
    height: 100px;
}
.box-red{background-color: red;
    border-radius: 8px;
    padding: 12px;
    color: #ffffff;
    margin: 10px 0;font-family: "regular";height: 100px;}
.box{box-shadow: rgba(0,0,0,.05)0 6px 24px 0,rgba(0,0,0,.08)0 0 0 1px;
        padding: 30px; 
        border-radius: 8px;
    text-align: center;font-family: "regular";}
.box-icon{ 
    background-color: #ffc105; 
    padding: 12px; border-radius: 50%;
    display: inline-block; margin: 12px auto;
}
/* counter start */
.counter-box{font-size: 40px; font-family: "bold"; color: #fff;}
.counter{background-image: url(../assets/images/parallax.jpg); 
    background-repeat: no-repeat; 
    background-size: cover;
    background-attachment: fixed;}
/* counter end */
/* prdoucts start */
.prdct-card {
    position: relative;
    background-color: #fff;
    border-radius: 12px;
    display: inline-block;
    overflow: hidden;
    box-shadow: 0 0 2px rgba(255, 193, 5, 0.749);
    transition: 1s;
    border: 0;
}
.prdct-card::before {
    content: "";
    position: absolute;
    background-color: #ffc105;
    height: 65px;
    width: 65px;
    bottom: -32px;
    left: -32px;
    transform: rotate(45deg);
    opacity: 0.7;
    transition: 0.5s;
}
.prdct-card:hover {
    background-color: #ffc105;
    color: white;
}
.prdct-card:hover::before {
    transform: rotate(134deg);
    opacity: 0;
}
.product-container {
    width: 250px;
    margin: 0 auto;
}
.product-container img {
    border-radius: 0 0 0 70px;
    transition: 1.2s;
}
 /* sale bg start*/
 .salebg {
    position: relative;
}
.salebg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #ffc105;
    clip-path: polygon(0 0, 85% 0, 95% 102%, 0% 101%);
}
.sale {
    background-image: url(../assets/images/shape1.png);
    background-position: -145px center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 40px;
    padding-bottom: 30px;
}
 /* sale bg  end*/
/* about start */
.choose{
    background: #ffc105;
    color: #181515;
    padding: 16px;
    border-radius: 10px;
    font-size: 24px;
    display: inline-flex;
}



/* footer css */
.fixed{position:fixed; z-index:2;}
.priceicn{width:45px;}
.priceicn2 {width: 120px;}
.point{bottom:10px; left:10px;}
.point3{bottom:60px; left:10px;}
.point4{bottom:110px; left:10px;}
.point1{bottom:70px; left: 10px;}
.point2{bottom: 15px;right: 10px;}
.blink img{animation: blink 1s;
    animation-iteration-count: infinite;
    -webkit-animation: blink 1s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: infinite;}
@keyframes blink {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}}
@-webkit-keyframes blink {
    0% {opacity: 1;}
    50% { opacity: 0;}
    100% {opacity: 1;}} 
    @media (min-width:1px) and (max-width:767.98px){
    .salebg::before { clip-path: none;}
    .sale{background-size: cover;}}